import React from "react";
import Layout from "../components/layout";
import styles from "./parenting-classes.module.css";
import globalStyle from "../styles/global.module.css"
import Community from "../components/community";
import {Container, Col, Row, Button} from "react-bootstrap";

const ParentingClassesPage =  () => {

  return (
    <Layout className="site-content">
      <Container className={styles.parentingClassesContainer}>
        <div>
          <h1 className={globalStyle.entryTitle}>Parenting Community</h1>
        </div>
        <div>
          <div className={styles.virtualGroups}>
            <Community/>
            <div className={styles.virtualGroupsTitle}>Workshops and Virtual Village Groups</div>
          </div>

          <h2 className={styles.subTitle}>Better support for modern parenthood.</h2>

          <Row className={styles.content}>
            <Col>
              <p> Workshops dedicated to topics that matter most to you. These 90-minute live sessions are led by experts in their field covering everything from sleep training to whole nutrition for the family. These live session allow you the opportunity to ask your questions to ensure you leave educated, empowered, and inspired.</p>
              <Button className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink]} href="/mom-workshops/" role="button">Mom workshops</Button>
            </Col>
            <Col>
              <p>Our six-week Virtual Village Groups are designed to build community between you and like-minded parents. Our Newborn track offers support to parents with little ones 0-6 months and is led by Ali Glinsky. Our Toddler Track is for those parenting toddlers and is led by LMFT Hayley Goldberg.</p>
              <Button className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink]} href="/six-week-series/" role="button">Virtual Village Groups</Button>
            </Col>
          </Row>

          <h2 className={styles.subTitle}>+ BONUS CONTENT!</h2>

          <div>
            <p>
              When you join our village, either through our virtual classes or our workshops, you get access to our exclusive BONUS video content! Private interviews of leading experts led by our very own Ali Glinsky that will empower you as an individual and as a parent!
            </p>
            <p>
              The interviews include experts in Pediatric Nutrition, Mindful Parenting, Personal Brand Style as a New Mom, Baby and Toddler Sleep, Marriage After Kids and so much more! The interviews are all accessible online so you can sit back and watch from the comfort of your home or office. This content is sure to help you along in any part of your parenting journey.
            </p>
          </div>
        </div>

      </Container>
    </Layout>
  )
};

export default ParentingClassesPage
